import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField
} from 'react-admin';

const QuizList: React.FC<{}>= props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField  source="name" label='quiz.name' />
      </Datagrid>
    </List>
  );
}

export default QuizList;