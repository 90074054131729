import React, {  } from 'react';
import { Edit, SimpleForm } from 'react-admin';

import QuizForm from './QuizForm';
import CustomToolbar from '../../shared/components/dialogs/CustomToolbarEdit';

const QuizEdit : React.FC<{}> = props => {
  return (
    <Edit>
      <QuizForm isEdit toolbar={<CustomToolbar />} />
    </Edit>
  )
}

export default QuizEdit;
