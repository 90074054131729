import React, {  } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const QuizCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list' >
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source='name' label='quiz.name' /> 
    </SimpleForm>
  </Create>

export default QuizCreate;