import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput, FormTab, TabbedForm, Datagrid, ReferenceManyField, TextField, useGetList } from 'react-admin';

import { TabbedShowLayout, Tab } from '../../shared/components/tabs';
import AnimalList, { AnimalGrid } from '../animal/AnimalsList';

interface RefugeFormProps {
  isEdit: boolean;
}

const RefugeForm : React.FC<RefugeFormProps>= (props) => {
  const { data, total, isLoading, error } = useGetList('animal');
  return (
    <TabbedForm warnWhenUnsavedChanges {...props}>
      <FormTab label='refuge.tabs.generic' >
        <TextInput source='name' label='refuge.name' />
        <TextInput source='mail' label='refuge.email' />
        <TextInput source='phone' label='refuge.phone' />
        <TextInput source='siret' label='refuge.siret' />
        <TextInput source='address' label='refuge.address' />
        <TextInput source='rna_hk' label='refuge.rna_hk' />
      </FormTab>
      {
        props.isEdit &&
        <FormTab label='refuge.tabs.user' >
          <ReferenceManyField reference="user-refuge" target="refugeId" label="" fullWidth>
            <Datagrid>
              <TextField source='firstname' label='refuge.firstname' />
              <TextField source='lastname' label='refuge.lastname' />
              <TextField source='phone' label='refuge.phone' />
              <TextField source='mail' label='refuge.mail' />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      }
      {
        props.isEdit &&
        <FormTab label='refuge.tabs.animal' >
          <AnimalGrid data={data} isLoading={isLoading}  />
        </FormTab>
      }
    </TabbedForm>
  );
};

export default RefugeForm;