import React from 'react';
import { TextInput} from 'react-admin';

const QuestionForm : React.FC<{}>= props => {
  return (
    <>
      <TextInput source="key" label="questions.key"  />
    </>
  )
}

export default QuestionForm;