import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination 
} from 'react-admin';

const PostList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<PostPagination />}
    >
      <Datagrid rowClick="edit">
        <ReferenceField reference='animal-type' source='animalType' label='breed.animalType'>
          <TextField source='name' label='breed.animalType' />
        </ReferenceField>
        <TextField  source="name" label='breed.name' />
      </Datagrid>
    </List>
  );
}

export default PostList;