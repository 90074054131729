import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput } from 'react-admin';

const CriteriaForm : React.FC<{}>= (props) => {
  return (
    <>
      <TextInput source="key" label='criteria.key' />
      <TextInput source="value" label='criteria.value' />
    </>
  );
};

export default CriteriaForm;