import React from 'react';
import { DeleteButton, useTranslate } from 'react-admin';
import { RaRecord } from 'ra-core';

interface QuizDeleteButtonProps {
  record? : RaRecord;
  cellClassName? : any;
  data?: any;
}

const QuizDeleteButton : React.FC<QuizDeleteButtonProps> = (props) => {
  return (
    props.data.id ?
      <DeleteButton
        label=""
        redirect={false}
        {...props}
        /> :
      null
  );
}

export default QuizDeleteButton;
