import { fetchUtils } from 'react-admin';
import { DataProvider } from 'ra-core';
import { stringify } from 'query-string';
import flatten from 'flat';
import { serialize } from '../../shared/utils/tools';
import config from '../../providers/configprovider';


const httpClient = fetchUtils.fetchJson;

/*
Uggly hack necessary to filter results in ranges. For example between min date and max date.
This is done by providing the following query to the REST API:

f=date;le;YYYY-MM-DD&f=date;ge;YYYY-MM-DD

Since the query parameter is 'f' for both min date and max date, this cannot be handled
by react admin. So in the React Admin filter we use source names as folows:

<DateInput source="f.date.ge" label="Min Date" />
<DateInput source="f.date.le" label="Max Date" />

This creates the following params object :

{
 "f.date.ge" : "YYYY-MM-DD",
 "f.date.le" : "YYYY-MM-DD",
}

which is converted by stringify into:

f.date.ge=YYYY-MM-DD&f.date.le=YYYY-MM-DD

The regexp then converts this string into the valid query.

*/
const queryFilterArray = (query : string) : string =>
  query?.replace(/f\.([a-zA-Z]+)\.(gt|ge|lt|le)=/g, 'f=$1;$2;');

/*
  Data provider object
*/
const createDefaultDataProvider = (apiUrl : string, ttl = 0) : DataProvider => ({
  getList : async (resource, params) => {
    
    const { page, perPage } = params.pagination || {};
    const { field, order }  = params.sort || {};
    const filter            = params.filter || {};
  
    const query = {
      ...flatten<typeof filter, typeof filter>(filter),
      sort: [[field, order]],
      page: JSON.stringify(page - 1),
      pageSize: JSON.stringify(perPage),
    };
    const url = `${apiUrl}/${resource}?${queryFilterArray(stringify(query))}`;
    
    return httpClient(url, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => ({
      data: [...json.data],
      total: json.pagination.itemsCount,
      ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
    }));
  },

  getOne : async (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => {
      return {
        data: json,
        ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
      }
  }),

  getMany : async (resource, params) => {
    const { ids = [] }  = params || {};

    const query = {
      id : ids.join(',')
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }})
      .then(({ json } : any) => ({
         data: [...json.data],
         ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
       }));
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order }  = params.sort;
    const filter            = params.filter;

    const query = {
      ...flatten<typeof filter, typeof filter>(filter),
      sort: [[field, order]],
      page: JSON.stringify(page - 1),
      pageSize: JSON.stringify(perPage),
    };

    const url = `${apiUrl}/${resource}?${queryFilterArray(stringify(query))}`;

    return httpClient(url, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => ({
      data: [...json.data],
      total: json.pagination.itemsCount
    }));
  },

  create : async (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: params.data.__file__ ? ((file = {}, {animalType}: any) => {
        const formData = new FormData();
        formData.append('file', file.rawFile);
        if (animalType){
          formData.append('animalType', animalType);
        }
        return formData;
      })(params.data.__file__, params.data) : JSON.stringify(params.data),
      user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }
    }).then(({ json } : any) => ({
        data: { ...params.data, id: json.id },
    })),

  update : async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
      user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }
    }).then(({ json } : any ) => ({ data: json }))
  },

  updateMany : async (resource, params) =>
    Promise
      .all(params.ids.map(id => fetchUtils.fetchJson(`${apiUrl}/${resource}/${id}`, { method: 'UPDATE', user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }})))
      .then(responses => ({ data: responses.map(response => response.json), })),

  delete : async (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'DELETE',
        user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }
    }).then(({ json } : any) => ({ data: json })),

  deleteMany : async (resource, params) =>
    Promise
      .all(params.ids.map(id => fetchUtils.fetchJson(`${apiUrl}/${resource}/${id}`, { method: 'DELETE', user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }})))
      .then(responses => ({ data: responses.map(response => response.json), })),
});

export default createDefaultDataProvider;
