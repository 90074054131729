import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination 
} from 'react-admin';

const PostList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<PostPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source='name' label='refuge.name' />
        <TextField source='mail' label='refuge.email' />
        <TextField source='phone' label='refuge.phone' />
        <TextField source='siret' label='refuge.siret' />
        <TextField source='rna_hk' label='refuge.rna_hk' />
        <TextField source='address' label='refuge.address' />
      </Datagrid>
    </List>
  );
}

export default PostList;