import React, { Fragment, useState } from 'react';

import { Button, useUpdate, useNotify, SaveButton, Edit, Form } from 'react-admin';
import { RaRecord } from 'ra-core';
import { useForm } from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { Box } from '@material-ui/core';

const QuickEditDialog : React.FC<QuickEditDialogProps> = (props) => {
  const {
    Layout,
    onChange,
    targetResource,
    ariaLabel = '',
    title = '',
    target,
    label = 'ra.action.edit',
    record
  } = props;

  const [visible, setVisible] = useState(false);
  const [update, { data, isLoading, error }] = useUpdate(targetResource, {id : record?.id});
  const notify = useNotify();

  const handleShow = () => setVisible(true);
  const handleHide = () => setVisible(false);

  const _handleSubmit = (data : any) => {
    update(targetResource, {data}, {
      onSuccess : ({data} : {data : RaRecord}) => {
        handleHide();
      },
      onError: (error : any) => {
        handleHide();
        notify(`Resource creation error: ${error.message}`)
      }
    })
  };

  return (
    <Fragment>
      <Button
        className={`quick-update-dialog-button`}
        onClick={handleShow}
        label={label}
      >
        <EditIcon />
      </Button>
      <Dialog
        fullWidth
        open={visible}
        onClose={handleHide}
        aria-label={ariaLabel || title}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <Form onSubmit={_handleSubmit} id="update_form" >
              <Box display={'flex'} flexDirection='column' sx={{padding : '20px 50px 0 50px'}}>
                <Layout />
              </Box>
              <Box display='flex' flexDirection={'row'} justifyContent='space-between' > 
                <Button
                  label="ra.action.cancel"
                  onClick={handleHide}
                  // disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton form="update_form" />
              </Box>
            </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export interface QuickEditDialogProps {
  Layout : React.FC;
  onChange? : (data : any) => void;
  targetResource : string;
  ariaLabel? : string;
  title : string;
  target? : string;
  label? : string;
  transform? : (data : RaRecord) => RaRecord;
  record : RaRecord
}

export default QuickEditDialog;
