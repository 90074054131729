import React from 'react';
import { 
  TextInput, 
  ReferenceInput, 
  SelectInput, 
  DateInput, 
  BooleanInput, 
  ImageInput, 
  ImageField, 
  useGetList, 
  NumberInput, 
  FormDataConsumer, 
  AutocompleteInput,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  AutocompleteArrayInput, 
  useTranslate,
  useInput
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';
import { Criteria } from '../../types';

const ageChoices = [{id : 'jeune', name : 'jeune'}, {id : 'adulte', name : 'adulte'}, {id : 'senior', name : 'senior'}];
const genreChoices = [{id : 'un mâle', name : 'un mâle'}, {id : 'une femelle', name : 'une femelle'}];
const sizeChoices = [{id : 'petite taille', name : 'petite taille'}, {id : 'moyenne taille', name : 'moyenne taille'}, {id : 'grande taille', name : 'grande taille'}];

interface AnimalFormProps {
  isEdit?: boolean; 
  data?: any;
}

const AnimalForm : React.FC<AnimalFormProps>= (props) => {
  const {data : listCriterias = []} = useGetList('criterias');
  const translate = useTranslate();
  
  
  const Breed = () => {
    const { field } = useInput({ source : "animalTypeId" });
    return (
      <ReferenceArrayInput source="breeds" reference="breed" filter={{animalType : field?.value}}>
        <AutocompleteArrayInput label='animal.breeds' optionText="name"  source='name' />
      </ReferenceArrayInput>
    )
  }
  
  return (
    <TabbedForm warnWhenUnsavedChanges {...props}>
      <FormTab label='animal.tabs.generic'>
        <div>
          <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                  {translate(
                      'animal.sections.identity'
                  )}
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <TextInput source="name" label='animal.name' />
                  </Box>
                  <Box>
                    <DateInput source="birthday" label='animal.birthday' />
                  </Box>
                  <Box>
                    <TextInput source="identifier" label='animal.identifier' />
                  </Box>
                  <Box>
                    <TextInput source="health" label='animal.health' multiline style={{width : 400}} sx={{textarea : {height : '60px !important'}}} />
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="description" label='animal.description' multiline style={{width : 500, marginLeft : 100}} sx={{textarea : {height : '250px !important'}}} />
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                  {translate(
                      'animal.sections.refuge'
                  )}
              </Typography>
              <Box>
                <ReferenceInput reference='refuge' source='refugeId'>
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Typography variant="h6" gutterBottom>
                  {translate(
                      'animal.sections.generalCriterias'
                  )}
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <ReferenceInput reference='animal-type' source='animalTypeId' sort={{field : "name", order : "ASC"}} >
                      <SelectInput optionText='name' label='animal.animalType' />
                    </ReferenceInput>
                  </Box>
                  <Box>
                    <SelectInput source="size" label='animal.size' choices={sizeChoices} />
                  </Box>
                  <Box>
                    <Breed />
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <SelectInput source='genre' choices={genreChoices} />
                  </Box>
                  <Box>
                    <SelectInput source='age' choices={ageChoices} />
                  </Box>
                  <Box>
                    <ReferenceArrayInput source="colors" reference="color">
                      <AutocompleteArrayInput optionText="key" label='animal.colors'  />
                    </ReferenceArrayInput>
                  </Box>
                </Box>
              </Box>
              <Box width={90} >
                <NumberInput source="weight" label='animal.weight' />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="vaccined" label='animal.vaccine' />
                <BooleanInput source="verified" label='animal.verified' />
              </Box>
            </Grid>
          </Grid>
        </div>
      </FormTab>
      <FormTab label='animal.tabs.photos'>
        <ImageInput source="photos" label="Image" accept="image/*" multiple={true} >
          <ImageField source="src" title="title" sx={{ img : {width : '250px !important', height : '250px !important'}}} />
        </ImageInput>
      </FormTab>
      <FormTab label='animal.tabs.criterias'>
        {
          props.isEdit ?
          <>
            <FormDataConsumer>
              {({ formData }) => {
                  return formData?.criterias.map(({key, value}: Criteria, index: number) => {
                    return (
                      <BooleanInput label={listCriterias?.find(({key: keyC}) => keyC === key)?.value || ''} source={`criterias[${index}].value`} />
                  )})
              }}
            </FormDataConsumer>  
            <FormDataConsumer>
              {({ formData }) => {
                const criteriasNotSetted = listCriterias.filter(({key}) => !_.includes(formData?.criterias.map(({key}: Criteria) => key), key))
                  return criteriasNotSetted?.map(({key}: Criteria) => {
                    return (
                      <BooleanInput label={listCriterias?.find(({key: keyC}) => keyC === key)?.value || ''} source={`newCriterias.${key}`} />
                  )})
              }}
            </FormDataConsumer>  
          </> :
          listCriterias?.map(({key, value}) => <BooleanInput label={value} source={`criterias.${key}`} />)
        }
      </FormTab>
    </TabbedForm>
  );
};

export default AnimalForm;
