import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput } from 'react-admin';

import { TabbedShowLayout, Tab } from '../../shared/components/tabs';

const UserForm : React.FC<{}>= (props) => {
  return (
    <>
      <ReferenceInput reference={'animal-type'} source="animalType">
        <SelectInput source='name' label='breed.animalType' />
      </ReferenceInput>

      <TextInput source="name" label='breed.name' />
    </>
  );
};

export default UserForm;