import React, {  } from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const HairEdit : React.FC<{}> = props =>
  <Edit mutationMode='undoable' {...props}>
    <SimpleForm>
      <TextInput disabled source="key" label='animalType.key' />
    </SimpleForm>
  </Edit>;

export default HairEdit;
