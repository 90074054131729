import React from 'react';
import { useTranslate, useRefresh } from 'react-admin';
import { RaRecord } from 'ra-core';
import { useParams } from 'react-router-dom';

import QuickCreateDialog from '../../../shared/components/dialogs/QuizCreateDialog';
import QuestionForm from './QuestionForm';

interface QuizCreateButtonProps {
  record? : RaRecord;
}

const QuizCreateButton : React.FC<QuizCreateButtonProps> = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const { id } = useParams();
  return (
    id ?
    <div style={{marginTop : '15px'}} >
      <QuickCreateDialog
        targetResource="question"
        title={translate('questions.quizCreateTitle')}
        Layout={QuestionForm}
        transform={(data: any) => ({...data, quizId : id})}
        onChange={refresh}
      />
    </div> : null
  );
}

export default QuizCreateButton;
