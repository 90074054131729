import React, {  } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const ColorCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list'>
    <SimpleForm>
      <TextInput source="key" label='animalType.key' />
    </SimpleForm>
  </Create>

export default ColorCreate;