import React, {  } from 'react';
import { 
  TextInput,
  TabbedForm, 
  FormTab, 
  ReferenceManyField, 
  Datagrid, 
  TextField, 
  FormDataConsumer,
} from 'react-admin';

import QuizCreateButton from './components/QuizCreateButton';
import QuizDeleteButton from './components/QuizDeleteButton';
import QuizEditButton from './components/QuizEditButton';

const QuizForm : React.FC<{isEdit? : boolean, toolbar? : any}> = (props) => {
  return (
    <TabbedForm warnWhenUnsavedChanges {...props}>
      <FormTab label='quiz.tabs.quiz'>
        <TextInput sx={{fontSize : '18px', width : 350}} source="name" label="quiz.name" />
      </FormTab>
      {
        props.isEdit ?
        <FormTab label='quiz.tabs.questions'>
            <ReferenceManyField reference="question" target="quizId" label="" fullWidth>
              <Datagrid isRowSelectable={() => false} >
                <TextField source="key" sortable={false} label="questions.key" />
                <FormDataConsumer>
                  {({ formData }) => {
                    return (
                      <QuizDeleteButton data={formData} />
                    )
                  }}
                </FormDataConsumer>
                <QuizEditButton />
              </Datagrid>
            </ReferenceManyField>
            <QuizCreateButton />
        </FormTab> : 
        null
      }
     </TabbedForm>
  );
};

export default QuizForm;