import React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';

import UploadFileButton from './UploadFileButton';

const ListToolbar  : React.FC<{}> = () => {
  return (
    <TopToolbar style={{display : 'flex', alignItems : 'center'}}>
      <CreateButton />
      <UploadFileButton />
    </TopToolbar>
  )
}

export default ListToolbar;