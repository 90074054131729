import React, {  } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const AnimalTypeCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list'>
    <SimpleForm>
      <TextInput source="key" label='animalType.key' />
      <TextInput source="name" label='animalType.name' />
    </SimpleForm>
  </Create>

export default AnimalTypeCreate;