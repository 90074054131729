import { toLower } from 'lodash';
import React from 'react';
import { Edit, Toolbar, Button, ShowButton, SaveButton, DeleteButton, useTranslate } from 'react-admin';

const CustomToolbar = (props : any) => {
  const translate = useTranslate();
  const { resource } = props;
  return (
    <Toolbar {...props} style={{display : 'flex', justifyContent : 'space-between'}}>
        <SaveButton />
        <DeleteButton confirmTitle={translate(`deleteMessages.${resource}`)}/>
    </Toolbar>
  )
};

export default CustomToolbar;