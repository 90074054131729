import React, {useState, useEffect, ChangeEvent} from "react";
import { useTranslate, useAuthProvider, useDataProvider, SaveButton, Toolbar, useStoreContext } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  form : {
    marginTop : theme.spacing(5),
    width : '100%'
  },
  input : {
    width : theme.spacing(32)
  },
  helper : {
    height : '30px'
  }
}));

const Settings = () => {
  const store = useStoreContext();
  const profile = store.getItem('Profile');
  const translate = useTranslate();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);

  const [password, setPassword] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string>(profile?.meta?.firstName);
  const [lastName, setLastName] = useState<string>(profile?.meta?.lastName);
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();

  useEffect(() => {
    setFirstName(profile?.meta?.firstName);
    setLastName(profile?.meta?.lastName);
  }, [profile]);

  useEffect(() => {
    setPristine(
      profile.meta?.firstName === firstName &&
      profile.meta?.lastName === lastName &&
      !password
    )
  }, [profile, firstName, lastName, password]);


  const handleFirstNameChange = (event : ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value);
  const handleLastNameChange = (event : ChangeEvent<HTMLInputElement>) => setLastName(event.target.value);

  const handleClickShowPassword = () => setVisible(!visible);

  const handleMouseDownPassword = (event : any) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if(password && password.trim()) {
        if(!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password)) {
          setErrorMessage(translate('user.firstConnection.wrongPassword'));
          setError(true);
        }
        else {
          setErrorMessage('');
          setError(false);
          await authProvider.changePassword(password);
          setPassword('');
        }
      }

      await authProvider.updateProfile(
        {
          ...profile,
          meta : {
            ...profile.meta,
            firstName,
            lastName
          }
        }
      );
      store.setItem('Profile', {...profile, meta : {...profile.meta, firstName, lastName}})

      setLoading(false);
    }
    catch (error) {
      setLoading(false);
    }
  }

  return (
    <Card>
      <Title title={translate('user.settings.pageTitle')} />
      <CardContent>
        <Typography variant="h4">
          {translate('user.settings.title', {name : `${profile.meta?.firstName} ${profile.meta?.lastName}`})}
        </Typography>
        <FormControl className={classes.form} variant="filled">
          <InputLabel htmlFor="email" shrink>{translate('user.settings.email')}</InputLabel>
          <FilledInput
            id="email"
            value={profile.meta?.email}
            className={classes.input}
            disabled
          />
        </FormControl>
        <FormControl className={classes.form} variant="filled">
          <InputLabel htmlFor="firstName" shrink>{translate('user.settings.firstName')}</InputLabel>
          <FilledInput
            id="firstName"
            value={firstName}
            className={classes.input}
            onChange={handleFirstNameChange}
          />
        </FormControl>
        <FormControl className={classes.form} variant="filled">
          <InputLabel htmlFor="lastName" shrink>{translate('user.settings.lastName')}</InputLabel>
          <FilledInput
            id="lastName"
            value={lastName}
            className={classes.input}
            onChange={handleLastNameChange}
          />
        </FormControl>
        <FormControl className={classes.form} variant="filled">
          <InputLabel htmlFor="password">{translate('user.settings.password')}</InputLabel>
          <FilledInput
            id="password"
            type={visible ? 'text' : 'password'}
            name="password"
            onChange={event => setPassword(event.target.value)}
            className={classes.input}
            error={error}
            value={password}
            endAdornment={
               <InputAdornment position="end">
                 <IconButton
                   aria-label="toggle password visibility"
                   onClick={handleClickShowPassword}
                   onMouseDown={handleMouseDownPassword}
                 >
                   {visible ? <Visibility /> : <VisibilityOff />}
                 </IconButton>
               </InputAdornment>
             }
            />
          <FormHelperText error className={classes.helper}>
            {errorMessage}
          </FormHelperText>
        </FormControl>
      </CardContent>
      <Toolbar>
        <SaveButton
          disabled={loading || pristine}
          saving={loading}
          invalid={error}
          onClick={handleSubmit}
        />
      </Toolbar>
    </Card>
  );
}
export default Settings;
