import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const AnimalTypesList : React.FC<{}>= props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField  source="name" label='animalType.name' />
        <TextField  source="key" label='animalType.key' />
      </Datagrid>
    </List>
  );
}

export default AnimalTypesList;
