import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination,
  Create 
} from 'react-admin';

const CriteriaList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  return (
    <List
      {...props}
      pagination={<PostPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField  source="value" label='criteria.value' />
        <TextField  source="key" label='criteria.key' />
      </Datagrid>
    </List>
  );
}

export default CriteriaList;
