import React from 'react';
import { useTranslate } from 'react-admin';
import { RaRecord, useRecordContext } from 'ra-core';

import QuickEditDialog from '../../../shared/components/dialogs/QuizEditDialog';
import QuestionForm from './QuestionForm';

interface QuizEditButtonProps {
  record? : any;
  cellClassName? : any;
  data?: any;
}

const QuizEditButton : React.FC<QuizEditButtonProps> = (props) => {
  const translate = useTranslate();
  const record = useRecordContext()

  return (
    record?.id ?
      <QuickEditDialog
        targetResource="question"
        title={translate('questions.quizEditTitle')}
        Layout={QuestionForm}
        record={record}
        label=""
      /> :
      null
  );
}

export default QuizEditButton;
