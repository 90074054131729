import React, {  } from 'react';
import { Create, SaveContextProvider, useCreateController } from 'react-admin';

import AnimalForm from './AnimalForm';

const AnimalCreate : React.FC<{}>= props => {
  const { save : saveAnimal } = useCreateController({ resource: 'animal' });

  const save = (data: any) => {
    if(saveAnimal){
      const newData = {
        ...data,
        photos : data.photos.map(({id, rawFile}: any) => id ? id : rawFile)
      }
      saveAnimal(newData);
    }
  }

  return (
    <Create {...props} redirect='list' >
      <SaveContextProvider value={{ save }}>
        <AnimalForm />
      </SaveContextProvider>
    </Create>
  )
}

export default AnimalCreate;