import React from 'react';
import { Edit, SaveContextProvider, useEditContext, useEditController } from 'react-admin';

import AnimalForm from './AnimalForm';

const AnimalEdit : React.FC<{}> = props => {
  const { save : saveAnimal } = useEditController({ resource: 'animal' });
  const save = (data: any) => {
    if(saveAnimal){
      const newData = {
        ...data,
        photos : data.photos.map(({id, rawFile}: any) => id ? id : rawFile)
      }
      saveAnimal(newData);
    }
  }

  return (
    <Edit {...props}>
      <SaveContextProvider value={{ save }}>
        <AnimalForm isEdit />
      </SaveContextProvider>
    </Edit>
  )
}

export default AnimalEdit;
