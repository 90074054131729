import React, {useState, useEffect} from "react";
import { useTranslate, useAuthProvider, useRedirect } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';

import theme from '../../shared/styles/theme';

const useStyles = makeStyles({
  container : {
    position : 'fixed',
    top : 0,
    left : 0,
    width : '100%',
    height : '100%',
    backgroundColor : theme.palette.secondary.main,
    display : 'flex',
    flexDirection : 'row',
    justifyItems : 'center',
    alignItems : 'center'
  },
  card : {
    margin : 'auto',
    minWidth : '60%',
    maxWidth : '60%',
    minHeight : '60%',
    display : 'flex',
    flexDirection : 'column'
  },
  cardContent : {
    flex : 1,
    display : 'flex',
    flexDirection : 'column'
  },
  formContainer : {
    flex : 1,
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
    justifyContent : 'center'
  },
  form : {
    width : '350px'
  },
  helper : {
    height : '70px'
  },
  button : {
    width : '120px',
    margin : 'auto'
  }
});

const FirstConnection = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const authProvider = useAuthProvider();
  const redirect = useRedirect();

  const handleClickShowPassword = () => setVisible(!visible);

  const handleMouseDownPassword = (event : any) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    if(!password || !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password)) {
      setErrorMessage(translate('user.firstConnection.wrongPassword'));
      setError(true);
    }
    else {
      setErrorMessage('');
      setError(false);
      setLoading(true);
      await authProvider.changePassword(password);
      await authProvider.checkAuth(true);
      setLoading(false);
      redirect('/');
    }
  }

  useEffect(() => {
    authProvider.checkAuth(true)
      .catch(() => {
        redirect('/');
      });
  }, []);

  return (
      <div className={classes.container}>
        <Card className={classes.card}>
            <Title title={translate('user.firstConnection.pageTitle')} />
            <CardContent className={classes.cardContent}>
              <Typography variant="h3">
                {translate('user.firstConnection.title')}
              </Typography>
              <p>
                <Typography variant="body1">
                  {translate('user.firstConnection.description')}
                </Typography>
              </p>
              <div className={classes.formContainer}>
                <FormControl className={classes.form} variant="filled">
                  <InputLabel htmlFor="password">{translate('user.firstConnection.password1')}</InputLabel>
                  <FilledInput
                    id="password"
                    type={visible ? 'text' : 'password'}
                    name="password"
                    onChange={event => setPassword(event.target.value)}
                    error={error}
                    value={password}
                    endAdornment={
                       <InputAdornment position="end">
                         <IconButton
                           aria-label="toggle password visibility"
                           onClick={handleClickShowPassword}
                           onMouseDown={handleMouseDownPassword}
                         >
                           {visible ? <Visibility /> : <VisibilityOff />}
                         </IconButton>
                       </InputAdornment>
                     }
                    />
                  <FormHelperText error className={classes.helper}>
                    {errorMessage}
                  </FormHelperText>
                  {
                    loading ?
                      <CircularProgress
                        className={classes.button}
                        /> :
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.button}
                        disabled={loading}
                        >
                        {translate('user.firstConnection.submit')}
                      </Button>
                  }
                </FormControl>
              </div>
            </CardContent>
        </Card>
      </div>
  );
}
export default FirstConnection;
