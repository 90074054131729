import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  FilterLiveSearch, 
  FilterList, 
  FilterListItem, 
  useGetList,
  Pagination 
} from 'react-admin';
import ListToolbar from './components/ListToolbar';
import { Card, CardContent } from '@mui/material';
import Pets from '@mui/icons-material/Pets';

const BreedList : React.FC<{}>= props => {
  const listAnimal = useGetList('animal-type');
  const PostFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 8, width: 200, height : 800 }}>
        <CardContent>
            <FilterLiveSearch  />
            <FilterList label="breed.filter" icon={<Pets />}>
              {
                listAnimal?.data?.map(({id, name, key}: any) => <FilterListItem key={id} label={name} value={{animalType : id}} />)
              }
            </FilterList>
        </CardContent>
    </Card>
  );

  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ListToolbar />}
      aside={<PostFilterSidebar />}
      pagination={<PostPagination />}
    >
      <Datagrid rowClick="edit">
        <ReferenceField reference='animal-type' source='animalType' label='breed.animalType'>
          <TextField source='name' label='breed.animalType' />
        </ReferenceField>
        <TextField  source="name" label='breed.name' />
      </Datagrid>
    </List>
  );
}

export default BreedList;
