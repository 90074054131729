import React, {  } from 'react';
import { Edit, SimpleForm } from 'react-admin';

import PostForm from './PostForm';

const PostEdit : React.FC<{}> = props =>
  <Edit mutationMode='undoable' {...props}>
    <PostForm />
  </Edit>;

export default PostEdit;
