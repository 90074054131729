import React, {  } from 'react';
import { Edit, SimpleForm } from 'react-admin';

import UserForm from './UserForm';

const UserEdit : React.FC<{}> = props =>
  <Edit mutationMode='undoable' {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <UserForm />
    </SimpleForm>
  </Edit>;

export default UserEdit;
