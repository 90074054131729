import React, {ReactElement} from 'react';
import { Resource } from 'react-admin';

import Pets from '@mui/icons-material/Pets';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import ImageIcon from '@material-ui/icons/Image';
import SettingIcon from '@material-ui/icons/Settings';

import BreedsList      from './components/breed/BreedsList';
import BreedCreate    from './components/breed/BreedCreate';
import BreedEdit      from './components/breed/BreedEdit';

import CriteriaList      from './components/criterias/CriteriasList';
import CriteriaCreate    from './components/criterias/CriteriaCreate';
import CriteriaEdit      from './components/criterias/CriteriaEdit';

import PostList      from './components/post/PostsList';
import PostCreate    from './components/post/PostCreate';
import PostEdit      from './components/post/PostEdit';

import QuizList      from './components/quiz/QuizsList';
import QuizCreate    from './components/quiz/QuizCreate';
import QuizEdit      from './components/quiz/QuizEdit';

import RefugeList      from './components/refuge/RefugesList';
import RefugeCreate    from './components/refuge/RefugeCreate';
import RefugeEdit      from './components/refuge/RefugeEdit';

import UserList      from './components/user/UsersList';
import UserCreate    from './components/user/UserCreate';
import UserEdit      from './components/user/UserEdit';

import AnimalList      from './components/animal/AnimalsList';
import AnimalCreate    from './components/animal/AnimalCreate';
import AnimalEdit      from './components/animal/AnimalEdit';

import AnimalTypeList      from './components/animalType/AnimalTypesList';
import AnimalTypeCreate    from './components/animalType/AnimalTypeCreate';
import AnimalTypeEdit      from './components/animalType/AnimalTypeEdit';

import ColorsList      from './components/color/ColorsList';
import ColorCreate    from './components/color/ColorCreate';
import ColorEdit      from './components/color/ColorEdit';

import HairsList from './components/hair/hairsList';
import HairCreate from './components/hair/HairCreate';
import HairEdit from './components/hair/HairEdit';

import BlogPicturesList from './components/blog-pictures/BlogPicturesList';
import BlogPictureCreate from './components/blog-pictures/BlogPictureCreate';
import BlogPictureEdit from './components/blog-pictures/BlogPictureEdit';

export const resources : ResourcesDefinition = [
  {
    __type : 'branch',
    name : 'config',
    icon : <SettingIcon />,
    children : [
      {
        __type : 'resource',
        name : 'animal-type',
        icon : <ListIcon />,
        list   : AnimalTypeList,
        create : AnimalTypeCreate,
        edit   : AnimalTypeEdit
      },
      {
        __type : 'resource',
        name : 'criterias',
        icon : <ListIcon />,
        list   : CriteriaList,
        create : CriteriaCreate,
        edit   : CriteriaEdit
      },
      {
        __type : 'resource',
        name : 'breed',
        icon : <ListIcon />,
        list   : BreedsList,
        create : BreedCreate,
        edit   : BreedEdit
      },
      {
        __type : 'resource',
        name : 'color',
        icon : <ListIcon />,
        list   : ColorsList,
        create : ColorCreate,
        edit   : ColorEdit
      },
      {
        __type : 'resource',
        name : 'hair',
        icon : <ListIcon />,
        list   : HairsList,
        create : HairCreate,
        edit   : HairEdit
      },
    ]
  },
  {
    __type : 'resource',
    name : 'animal',
    icon : <Pets />,
    list   : AnimalList,
    create : AnimalCreate,
    edit   : AnimalEdit
  },
  {
    __type : 'branch',
    name : 'accounts',
    icon : <PeopleIcon />,
    children : [
      {
        __type : 'resource',
        name : 'user',
        icon : <PeopleIcon />,
        list   : UserList,
        create : UserCreate,
        edit   : UserEdit
      },
      {
        __type : 'resource',
        name : 'refuge',
        icon : <PeopleIcon />,
        list   : RefugeList,
        create : RefugeCreate,
        edit   : RefugeEdit
      },
    ]
  },
  {
    __type : 'resource',
    name : 'quiz',
    icon : <ListIcon />,
    list   : QuizList,
    create : QuizCreate,
    edit   : QuizEdit
  },
  {
    __type : 'branch',
    name : 'blog',
    icon : <ImageIcon />,
    children : [
      {
        __type : 'resource',
        name : 'post',
        icon : <ImageIcon />,
        list   : PostList,
        create : PostCreate,
        edit   : PostEdit
      },
      {
        __type : 'resource',
        name : 'blog-pictures',
        icon : <ImageIcon />,
        list   : BlogPicturesList,
        create : BlogPictureCreate,
        edit   : BlogPictureEdit
      },
    ]
  },
  {
    __type : 'resource',
    name : 'user-animal',
    __hidden : true
  },
  {
    __type : 'resource',
    name : 'question',
    __hidden : true
  },
  {
    __type : 'resource',
    name : 'user-refuge',
    __hidden : true
  },
  {
    __type : 'resource',
    name : 'location',
    __hidden : true
  },
];

interface RAViewProps {
  [key : string] : any
}

type RAResourceType = typeof Resource;
export type BranchType = {
  __type ?: 'branch'
  name : string;
  label ?: string;
  icon ?: ReactElement;
  children : ResourceType[];
};

interface ResourceLeaf {
  __type : 'resource';
  __hidden ?: boolean;
  name : string;
  label ?: string;
  icon ?: ReactElement;
  list    ?: React.FC<RAViewProps>,
  create  ?: React.FC<RAViewProps>,
  edit    ?: React.FC<RAViewProps>,
  options ?: { label ?: string}
};

interface DashboardLeaf {
  __type : 'dashboard';
  name : string;
  label ?: string;
  icon ?: ReactElement;
}

export type LeafType = ResourceLeaf | DashboardLeaf;
export type ResourceType = BranchType | LeafType;
export type ResourcesDefinition = ResourceType[];


export const isLeaf = (node : ResourceType) => !!node.__type && node.__type !== 'branch';
export const isBranch = (node : ResourceType) => !isLeaf(node);
export const isHidden = (node : ResourceType) => node.__type === 'resource' && !!node.__hidden;

function traverseResource<T>(group : ResourceType[], cumul : T[] = [], callback : (node : ResourceLeaf) => T) {
  group.forEach(
    node => {
      if(isLeaf(node) && node.__type === 'resource') {
        cumul.push(callback(node));
      }
      else if(isBranch(node)) {
        traverseResource((node as BranchType).children, cumul, callback);
      }
    }
  );

  return cumul;
}

export const resourcesList = traverseResource<ResourceLeaf>(
  resources,
  [],
  (node : ResourceLeaf) => node
);

export const resourceNames = traverseResource<string>(
  resources,
  [],
  (node : LeafType) => node.__type === 'resource' ? node.name : ''
).filter(n => !!n);
