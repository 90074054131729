import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customMessages : TranslationMessages = {
  ...frenchMessages,
  resources : {
    'animal'              : 'Les animaux',
    'config.animal-type'  : 'Types d\'animaux',
    'config.breed'        : 'Races',
    'config.criterias'    : 'Critères',
    'config.animal'       : 'Animaux à adopter',
    'config.color'        : 'Couleurs',
    'config.hair'         : 'Poils',
    'accounts'            : 'Les comptes utilisateurs', 
    'accounts.user'       : 'Utilisateurs',
    'accounts.refuge'     : 'Refuges',
    'quiz'                : 'Questionnaires',
    'blog'                : 'Blogs & Images',
    'blog.post'           : 'Blogs',
    'blog.blog-pictures'  : 'Images',
    'config'              : 'Configuration',
  },
  login : {
    lostButton : 'Réinitialiser le mot de passe',
    gotoLogin : 'Retour à la connexion',
    gotoLost : 'Mot de passe perdu ?',
    lostExecuted : 'Un mot de passe temporaire a été envoyé à votre adresse email.'
  },

  user : {
    firstConnection : {
      pageTitle : 'Première connexion',
      title : 'Bienvenue',
      description : 'Vous venez de vous connecter pour la première fois. Le mot de passe que vous avez reçu par email n\'est valable qu\'une fois. Merci de bien voulour le modifier ci-dessous.',
      password1 : 'Nouveau mot de passe',
      password2 : 'Confirmation',
      submit : 'Envoyer',
      wrongPassword : 'Le mot de passe doit avoir au moins 8 caractères dont au moins 1 chiffre et une lettre'
    },
    settings : {
      pageTitle : 'Réglages',
      title : 'Bienvenue %{name}',
      email : 'Email',
      password : 'Nouveau mot de passe',
      firstName : 'Prénom',
      lastName : 'Nom'
    },
    pristine : {
      'true'  : 'Non validé',
      'false' : 'Validé'
    }
  },
  usermenu : {
    profile : "test"
  },
  breed : {
    animalType : 'Animal',
    name : 'Race',
    status : 'Phase',
    active : 'Traitement',
    isActive : 'En cours',
    isNotActive : 'Terminé',
    filter : 'Filtrer par type',
    uploadDialog : {
      button : 'Importer',
      title : 'Téléchargement des fichiers',
      helper : 'Glissez-deposez ou cliquez ici',
      select : 'Sélectionnez un type d\'animal',
      description : `Glissez-deposez ou cliquez dans le rectangle ci-dessous pour télécharger les fichiers à traiter.`,
      uploadButton : 'Télécharger',
      cancel : 'Annuler',
    }
  },
  criteria : {
    key : 'Clé unique',
    value : 'Texte',
    status : 'Phase',
    active : 'Traitement',
    isActive : 'En cours',
    isNotActive : 'Terminé',
    filter : 'Filtrer par type',
  },
  animal : {
    name : 'Nom',
    animalType : 'Type d\'animal',
    breeds : 'Races',
    description : 'Description',
    health : 'Santé',
    weight : 'Poids en kg',
    colors : 'Couleurs',
    size   : 'Taille',
    birthday : 'Date de naissance',
    identifier : 'N° d\'indentification',
    vaccine : 'Vacciné',
    verified : 'Vérifié',
    refuge   : 'Refuge', 
    isActive : 'En cours',
    isNotActive : 'Terminé',
    filter : 'Filtrer par type',
    tabs : {
      generic : 'Général',
      criterias : 'Critères',
      photos : 'Photos'
    },
    sections : {
      identity : 'Identité',
      refuge : 'Refuge',
      generalCriterias : 'Critères généraux'
    },
    filters : {
      type : 'Type',
      genre : 'Genre',
      age : 'Âge',
      size : 'Taille',
      criterias : 'Critères'
    }
  },
  animalType : {
    key : 'Clé unique',
    name : 'Type animal'
  },
  refuge : {
    name : 'Nom',
    email : 'Email',
    phone : 'Téléphone',
    siret : 'SIRET',
    address : 'Adresse',
    rna_hk  : 'RNA ou HK',
    tabs : {
      generic : 'Général',
      user    : 'Membres',
      animal  : 'Animaux'
    }
  },
  quiz : {
    name : 'Nom du questionnaire',
    tabs : {
      questions : 'Questions',
      quiz : 'Questionniaire'
    }
  },
  questions : {
    quizCreateTitle : "Création d'une question",
    quizEditTitle : "Modification de la question",
    key : "Clé unique",
    value : "Intitulé de la question",
  },
  post : {
    title : "Titre",
    intro : 'Introduction',
    content : 'Contenu',
    resume : 'Résumé',
    category : 'Categorie',
    slug : 'slug',
    addImage : 'Ajoutez des images',
    insert : 'Insérer',
    createdAt : 'Créé le',
    tabs : {
      generic : 'Général',
      photos : 'Photos',
      editor : 'Éditeur'
    },
    photo : {
      main : 'Photo principale',
      thumbnail : 'Vignette'
    }
  },
  'blog-pictures' : {
    add : 'Ajouté le'
  }
};

export default customMessages;
