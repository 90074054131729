import { CreateParams, UpdateParams, DataProvider } from 'ra-core';
import _ from 'lodash';
import config from './../configprovider';
import createDefaultDataProvider from './default';
import uploadsPicturesProvider from './uploadsPictures';
import { fetchUtils } from 'react-admin';
import flatten from 'flat';
import { stringify } from 'query-string';
import { serialize } from '../../shared/utils/tools';

const httpClient = fetchUtils.fetchJson;

export const modelDataProvider : DataProvider = createDefaultDataProvider(config('MODEL_API_URL'), 30);

const queryFilterArray = (query : string) : string =>
  query?.replace(/f\.([a-zA-Z]+)\.(gt|ge|lt|le)=/g, 'f=$1;$2;');

export const createAnimalDataProvider = (apiUrl : string, ttl = 0) : DataProvider => ({
  ...modelDataProvider,
    create : async (resource, params) => {
      let newParams: CreateParams<any>;

      let photoIds: string[] = [];
      if(params?.data?.photos?.length){
        params.data.photos.forEach(async (photo: any) => {
          const res = await uploadsPicturesProvider.create('animal-pictures', photo);
          photoIds.push(res.data.id);
        });
      }
      if(!Array.isArray(params.data.criterias)){
        const criterias = Object.entries(params.data.criterias).map(([key, value]) => ({key, value}))
        newParams = {
          ...params,
          data : {
            ...params.data,
            criterias,
            photos : photoIds
          }
        }
      } else {
        newParams = {
          ...params,
          data : {
            ...params.data,
            photos : photoIds
          }
        };
      }
    
      return modelDataProvider.create(resource, newParams)
    },
  update : async (resource, params) => {
    let newParams: UpdateParams<any>;
    let photoIds: string[] = [];
    const { previousData } = params;

    const picturesToDelete = previousData.photos.filter((photoId: string) => !_.includes(params?.data?.photos, photoId));
    const picturesToCreate = params?.data?.photos.filter((photo: any) => photo.rawFile);
    const existingPictures = params?.data?.photos.filter((photo: any) => !photo.rawFile);

    if(picturesToDelete.length){
      picturesToDelete.forEach(async (photo: string) => {
        await uploadsPicturesProvider.delete('animal-pictures', {id : photo});
      });
    }
    if(picturesToCreate?.length){
      const photosPromises = picturesToCreate.map((photo: any) => {
        const newParams = {
            data : {
              __file__ : photo
            }
          }
        return uploadsPicturesProvider.create('animal-pictures', newParams)
      })
      const photosCreated = await Promise.all(photosPromises);
      photoIds = photosCreated.map((photo) => photo.data.id)
    }

    if(params.data.newCriterias){
      const criterias = Object.entries(params.data.newCriterias).map(([key, value]) => ({key, value}))
      newParams = {
        ...params,
        data : {
          ...params.data,
          criterias : [...params?.data.criterias, ...criterias],
          photos : [...existingPictures, ...photoIds]
        }
      }
    } else {
      newParams = {
        ...params,
        data : {
          ...params.data,
          photos : [...existingPictures, ...photoIds]
        }
      }
    }
    return modelDataProvider.update(resource, newParams)
  },
  getOne : async (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => {
      return {
        data: {...json, photos : json.photos.map((photoId: string) => ({id : photoId, src : `${config('UPLOAD_API_URL')}/animal-pictures/${photoId}/binary`, }))},
      ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
      }
      
  }),

  getList : async (resource, params) => {
    
    const { page, perPage } = params.pagination || {};
    const { field, order }  = params.sort || {};
    const filter            = params.filter || {};

    let url = '';
    console.log('FILTER', filter)
    if(filter.criterias){
      let myFilter = {
        ...filter,
        criterias : filter.criterias
      }
      const query = {
        ...myFilter,
        sort: [field, order],
        page: JSON.stringify(page - 1),
        pageSize: JSON.stringify(perPage),
      };
      url = `${apiUrl}/${resource}?${serialize(query, '')}`;
    } else {
      const query = {
        ...flatten<typeof filter, typeof filter>(filter),
        sort: [[field, order]],
        page: JSON.stringify(page - 1),
        pageSize: JSON.stringify(perPage),
      };
      url = `${apiUrl}/${resource}?${queryFilterArray(stringify(query))}`;
    }
    
    return httpClient(url, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => ({
      data: [...json.data],
      total: json.pagination.itemsCount,
      ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
    }));
  },

})