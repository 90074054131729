import React, {  } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

import BreedForm from './BreedForm';

const BreedCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list' >
    <SimpleForm warnWhenUnsavedChanges>
      <ReferenceInput reference={'animal-type'} source="key">
        <SelectInput source='name' label='breed.animalType' />
      </ReferenceInput>
      <TextInput source="name" label='breed.name' />
    </SimpleForm>
  </Create>

export default BreedCreate;