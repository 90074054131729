import React, { Fragment, useState } from 'react';

import { Button, useCreate, useNotify, SaveButton, Form, Create } from 'react-admin';
import { RaRecord } from 'ra-core';
import { useForm, useFormState } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import IconContentAdd from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';


const requiredProp = (name : string) =>
  console.error(`QuickCreateDialog requires a ${name} prop`);



const QuickCreateDialog : React.FC<QuickCreateDialogProps> = (props) => {
  const {
    Layout,
    onChange,
    targetResource,
    ariaLabel = '',
    title = '',
    target,
    label = 'ra.action.create',
    transform = data => data
  } = props;

  if (!targetResource) { requiredProp('targetResource'); }
  if (!Layout)         { requiredProp('Layout'); }

  const [visible, setVisible] = useState(false);
  const [create, { data, isLoading, error }]  = useCreate(targetResource);
  const notify = useNotify();
  const form = useForm();

  const handleShow = () => setVisible(true);
  const handleHide = () => setVisible(false);

  const _handleSubmit = (data : any) => {
    create(targetResource, {data : transform(data)}, {
      onSuccess : ({data} : {data : RaRecord}) => {
        handleHide();

        target && form.setValue(target, data.id);

        onChange && onChange(data);
      },
      // onFailure: (error : any) => notify(`Resource creation error: ${error.message}`)
    })
  };

  return (
    <Fragment>
      <Button
        className={`quick-create-dialog-button`}
        onClick={handleShow}
        label={label}
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={visible}
        onClose={handleHide}
        aria-label={ariaLabel || title}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Create>
            <Form onSubmit={_handleSubmit} id="create_form" >
              <Box display={'flex'} flexDirection='column' sx={{padding : '20px 50px 0 50px'}}>
                <Layout />
              </Box>
              <Box display='flex' flexDirection={'row'} justifyContent='space-between' > 
                <Button
                  label="ra.action.cancel"
                  onClick={handleHide}
                  // disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton form="create_form" />
              </Box>
            </Form>
          </Create>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export interface QuickCreateDialogProps {
  Layout : React.FC;
  onChange? : (data : any) => void;
  targetResource : string;
  ariaLabel? : string;
  title : string;
  target? : string;
  label? : string;
  transform? : (data : RaRecord) => RaRecord;
}

export default QuickCreateDialog;
